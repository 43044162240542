import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";

import { get } from "../api/MsApi";

const groupsAdapter = createEntityAdapter({
  selectId: (x) => x.name_grp,
});

export const fetchMatchingGroups = createAsyncThunk(
  "entities/fetchMatchingGroups",
  async (filters, { dispatch }) => {
    if (!filters) {
      return (await get({ dispatch, entity: "grp" })).data;
    } else {
      return (await get({ dispatch, entity: "grp", params: filters })).data;
    }
  }
);

export const fetchAllGroups = createAsyncThunk(
  "entities/fetchAllGroups",
  async ({ force } = {}, { getState, dispatch }) => {
    const state = getState();
    const totalGroups = selectTotalGroups(state);
    if (!force && (totalGroups || state.entities.groups.pending > 1)) {
      return;
    }
    dispatch(fetchMatchingGroups());
  }
);

export const slice = createSlice({
  name: "groups",
  initialState: groupsAdapter.getInitialState({ pending: 0 }),
  reducers: {
    clearData: (state) => groupsAdapter.setAll(state, []),
  },
  extraReducers: {
    [fetchMatchingGroups.pending]: (state, action) => {
      state.pending++;
    },
    [fetchMatchingGroups.fulfilled]: (state, { payload: data }) => {
      state.pending--;
      if (data) {
        groupsAdapter.upsertMany(state, data);
      }
    },
    [fetchMatchingGroups.rejected]: (state, action) => {
      state.pending--;
    },
  },
});

const {
  reducer,
  actions: { clearData },
} = slice;
export { clearData };
export default reducer;

export const {
  selectTotal: selectTotalGroups,
  selectAll: selectAllGroups,
} = groupsAdapter.getSelectors((state) => state.entities.groups);
