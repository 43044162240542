import { combineReducers } from "@reduxjs/toolkit";

import accountsReducer from "./accountsSlice";
import campaignsReducer from "./campaignsSlice";
import clientsReducer from "./clientsSlice";
import configReducer from "./configSlice";
import creativeReducer from "./creativeSlice";
import driveOrderReducer from "./driveOrderSlice";
import groupsReducer from "./groupsSlice";
import idAppsReducer from "./idAppsSlice";
import opesReducer from "./opesSlice";
import queryReducer from "./querySlice";
import statsReducer from "./statsSlice";
import storesReducer from "./storesSlice";

export default combineReducers({
  accounts: accountsReducer,
  clients: clientsReducer,
  opes: opesReducer,
  idApps: idAppsReducer,
  groups: groupsReducer,
  campaigns: campaignsReducer,
  creatives: creativeReducer,
  stats: statsReducer,
  query: queryReducer,
  config: configReducer,
  driveOrder: driveOrderReducer,
  stores: storesReducer,
});
