import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "msApi",
  initialState: { runningRequests: 0, lastError: null },
  reducers: {
    incrementRunningRequests: (state) => {
      state.runningRequests = state.runningRequests + 1;
    },
    decrementRunningRequests: (state) => {
      state.runningRequests = state.runningRequests - 1;
    },
    setLastError: (state, { payload: e }) => {
      state.lastError = e;
    },
  },
});
export const {
  reducer,
  actions: { incrementRunningRequests, decrementRunningRequests, setLastError },
} = slice;

export const selectRunningRequests = (state) => state.msApi.runningRequests;
export const selectLastError = (state) => state.msApi.lastError;
export default reducer;
