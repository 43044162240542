import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";

import { get } from "../api/MsApi";

const campaignAdapter = createEntityAdapter();

export const fetchNrOfCampaigns = createAsyncThunk(
  "msTest/nrOfCampaignsStatus",
  async (arg, { dispatch }) => {
    return (await get({ dispatch, entity: "campaign" })).data;
  }
);

export const slice = createSlice({
  name: "msTest",
  initialState: campaignAdapter.getInitialState({
    runningRequests: 0,
    lastError: null,
  }),
  reducers: {
    loaded: (state, data) => data.payload,
  },
  extraReducers: {
    [fetchNrOfCampaigns.pending]: (state, action) => {
      state.runningRequests++;
    },
    [fetchNrOfCampaigns.fulfilled]: (state, { payload: data }) => {
      campaignAdapter.setAll(state, data);
      state.runningRequests--;
    },
    [fetchNrOfCampaigns.rejected]: (state, action) => {
      state.nrOfCampaigns = null;
      state.runningRequests--;
      state.lastError = action.error;
    },
  },
});

const { reducer } = slice;
export default reducer;

export const {
  selectTotal: selectTotalCampaigns,
  selectAll: selectAllCampaigns,
} = campaignAdapter.getSelectors((state) => state.msTest);
