import axios from "axios";

import { getServerHostForEntity } from "./config";
import {
  decrementRunningRequests,
  setLastError,
  incrementRunningRequests,
} from "./msApiSlice";

function getURI({ entity, verb = "list", params }) {
  return `${getServerHostForEntity({
    entity,
  })}/webservices/rest/${verb}-${entity}${
    params
      ? `?${Object.entries(params)
          .map(
            ([name, value]) =>
              `${encodeURIComponent(name)}=${encodeURIComponent(
                Array.isArray(value) ? JSON.stringify(value) : value
              )}`
          )
          .join("&")}`
      : ""
  }`;
}

function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

const runningRequests = {};
let needsRedirectToLogin = false;

export async function axiosGet({ entity, verb, params }) {
  const url = getURI({ entity, verb, params });
  if (runningRequests[url]) {
    return await runningRequests[url];
  }
  const promise = axios({
    method: "get",
    url,
    withCredentials: true,
  });
  runningRequests[url] = promise;
  const result = await promise;
  delete runningRequests[url];
  return result;
}

export async function axiosPost({ entity, verb, params }) {
  const url = getURI({ entity, verb, params });
  if (runningRequests[url]) {
    return await runningRequests[url];
  }
  const promise = axios({
    method: "post",
    url,
    withCredentials: true,
  });
  runningRequests[url] = promise;
  const result = await promise;
  delete runningRequests[url];
  return result;
}

async function get({ dispatch, entity, verb, params = null }) {
  if (needsRedirectToLogin) {
    return;
  }
  await sleep(0);
  dispatch(incrementRunningRequests());
  try {
    return await axiosGet({ entity, verb, params });
  } catch (e) {
    if (e.response.status === 401) {
      // we're not logged, redirect to login
      needsRedirectToLogin = true;
      window.location = `${getServerHostForEntity({
        entity,
      })}/dashboard_new/go.php?url=${encodeURIComponent(window.location)}`;
      return;
    }
    dispatch(setLastError(e.toString()));
    throw e;
  } finally {
    dispatch(decrementRunningRequests());
  }
}

export { get };
