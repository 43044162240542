import React from "react";

import { initSentry } from "@mobsuccess-devops/react-sentry";

import "./App.css";
import Hotjar from "./components/Hotjar";
import DefaultEntryPoint from "./entry-points/Default.js";

function getSentryDsnFromEnv() {
  const dsnByUrlMatch = JSON.parse(
    process.env.REACT_APP_SENTRY_DSN_BY_URL_MATCH ?? "[]"
  );
  const { dsn } =
    dsnByUrlMatch.find(({ match }) =>
      window.location.href.match(new RegExp(match))
    ) ?? {};
  return dsn;
}

const dsn =
  window._msSentryDsn ||
  getSentryDsnFromEnv() ||
  "https://d5786d88f83242b5b544c0df5937aa49@o1028474.ingest.sentry.io/5995805";

initSentry({
  dsn,
  environment: process.env.REACT_APP_SENTRY_ENVIRONMENT ?? "local",
});

function App() {
  return (
    <div className="App">
      <Hotjar />
      <DefaultEntryPoint />
    </div>
  );
}

export default App;
