import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { get } from "../api/MsApi";

export const fetchConfig = createAsyncThunk(
  "entities/fetchConfig",
  async ({ force } = {}, { getState, dispatch }) => {
    return (await get({ dispatch, entity: "config" })).data;
  }
);

export const slice = createSlice({
  name: "config",
  initialState: { user: {}, rights: {} },
  reducers: {
    loaded: (state, data) => data.payload,
  },
  extraReducers: {
    [fetchConfig.pending]: (state, action) => {
      state.pending++;
    },
    [fetchConfig.fulfilled]: (state, { payload: data }) => {
      state.pending--;
      if (data) {
        Object.keys(data).forEach((key) => (state[key] = data[key]));
      }
    },
    [fetchConfig.rejected]: (state, action) => {
      state.pending--;
    },
  },
});

const { reducer } = slice;
export default reducer;

export const selectRights = (state) => state.entities.config.rights;
export const selectUser = (state) => state.entities.config.user;
export const selectCanViewMargin = (state) => selectRights(state).canViewMargin;
export const selectCanViewBid = (state) => selectRights(state).canViewBid;
export const selectCanManageDriveOrders = (state) =>
  selectRights(state).clickandcollect_gerer_commandes;
export const selectFirstname = (state) => selectUser(state).firstname;
export const selectLastname = (state) => selectUser(state).lastname;
export const selectEmail = (state) => selectUser(state).email;
