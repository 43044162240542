import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";

import { get } from "../api/MsApi";

const statsAdapter = createEntityAdapter({});

export const fetchStats = createAsyncThunk(
  "entities/fetchStats",
  async (filters, { dispatch }) => {
    if (!filters) {
      throw new Error("expected to fetch stats with filter");
    }
    return (await get({ dispatch, entity: "stats", params: filters })).data;
  }
);

export const slice = createSlice({
  name: "stats",
  initialState: statsAdapter.getInitialState({ pending: 0 }),
  reducers: {
    clearData: (state) => statsAdapter.setAll(state, []),
  },
  extraReducers: {
    [fetchStats.pending]: (state, action) => {
      state.pending++;
    },
    [fetchStats.fulfilled]: (state, { payload: data }) => {
      state.pending--;
      if (data) {
        statsAdapter.upsertMany(state, data);
      }
    },
    [fetchStats.rejected]: (state, action) => {
      state.pending--;
    },
  },
});

const {
  reducer,
  actions: { clearData },
} = slice;
export { clearData };
export default reducer;

export const {
  selectTotal: selectTotalStats,
  selectAll: selectAllStats,
} = statsAdapter.getSelectors((state) => state.entities.stats);
