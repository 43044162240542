import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";

import { get } from "../api/MsApi";

const accountAdapter = createEntityAdapter();

export const fetchAllAccounts = createAsyncThunk(
  "entities/fetchAllAccounts",
  async ({ force } = {}, { getState, dispatch }) => {
    const state = getState();
    const totalAccounts = selectTotalAccounts(state);
    if (!force && (totalAccounts || state.entities.accounts.pending > 1)) {
      return;
    }
    return (await get({ dispatch, entity: "account" })).data;
  }
);

export const slice = createSlice({
  name: "accounts",
  initialState: accountAdapter.getInitialState({ pending: 0 }),
  reducers: {
    loaded: (state, data) => data.payload,
  },
  extraReducers: {
    [fetchAllAccounts.pending]: (state, action) => {
      state.pending++;
    },
    [fetchAllAccounts.fulfilled]: (state, { payload: data }) => {
      state.pending--;
      if (data) {
        accountAdapter.setAll(state, data);
      }
    },
    [fetchAllAccounts.rejected]: (state, action) => {
      state.pending--;
    },
  },
});

const { reducer } = slice;
export default reducer;

export const {
  selectTotal: selectTotalAccounts,
  selectAll: selectAllAccounts,
} = accountAdapter.getSelectors((state) => state.entities.accounts);
