import React, { useMemo, createContext, useContext } from "react";

import EntryPointComponent from "../components/EntryPointComponent";

export const MsEntryPointContext = createContext();

export function getMsEntryPoint() {
  const msEntrypoints = window._msEntryPoints;
  if (msEntrypoints && msEntrypoints.length) {
    return msEntrypoints.shift();
  } else {
    return window._msEntryPoint;
  }
}

export default function MsEntryPoint() {
  const msEntryPointFromContext = useContext(MsEntryPointContext);
  const msEntryPoint = useMemo(() => {
    if (msEntryPointFromContext) {
      return;
    }
    return getMsEntryPoint();
  }, [msEntryPointFromContext]);
  return (
    <EntryPointComponent
      entryPoint={msEntryPointFromContext || msEntryPoint || "Dev"}
    />
  );
}
