import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";

import { get } from "../api/MsApi";

const driveOrderAdapter = createEntityAdapter();

export const saveAdjustedCart = createAsyncThunk(
  "entities/fetchOrderById",
  async (
    { orderId, adjustedCart, adjustedCartPrice } = {},
    { getState, dispatch }
  ) => {
    const result = (
      await get({
        dispatch,
        verb: "updateadjustedcart",
        entity: "driveOrder",
        params: {
          orderId,
          adjustedCart: JSON.stringify(adjustedCart),
          adjustedCartPrice,
        },
      })
    ).data;
    return result;
  }
);

export const fetchOrderById = createAsyncThunk(
  "entities/fetchOrderById",
  async ({ orderId } = {}, { getState, dispatch }) => {
    return (
      await get({
        dispatch,
        entity: "driveOrder",
        params: { orderId, cachebuster: Math.random() },
      })
    ).data;
  }
);

export const fetchOrdersBySearchFilter = createAsyncThunk(
  "entities/fetchOrdersBySearchFilter",
  async ({ search, searchBy, storeId } = {}, { getState, dispatch }) => {
    return (
      await get({
        dispatch,
        entity: "driveOrder",
        params: { search, searchBy, storeId },
      })
    ).data;
  }
);

export const fetchOrderByDisplayId = createAsyncThunk(
  "entities/fetchOrderById",
  async ({ orderDisplayId } = {}, { getState, dispatch }) => {
    return (
      await get({ dispatch, entity: "driveOrder", params: { orderDisplayId } })
    ).data;
  }
);

export const fetchAllOrdersForStore = createAsyncThunk(
  "entities/fetchAllOrdersForStore",
  async ({ storeId, fromDate, toDate } = {}, { getState, dispatch }) => {
    const result = (
      await get({
        dispatch,
        entity: "driveOrder",
        params: { storeId, fromDate, toDate },
      })
    ).data;
    return result;
  }
);

export const updateDriveOrderStatus = createAsyncThunk(
  "entities/updateDriveOrderStatus",
  async (
    { orderId, newStatus, extraParams = {} } = {},
    { getState, dispatch }
  ) => {
    const result = (
      await get({
        dispatch,
        verb: "updatestatus",
        entity: "driveOrder",
        params: { orderId, newStatus, ...extraParams },
      })
    ).data;
    return result;
  }
);

export const slice = createSlice({
  name: "driveOrder",
  initialState: driveOrderAdapter.getInitialState({ pending: 0 }),
  reducers: {
    loaded: (state, data) => data.payload,
  },
  extraReducers: {
    [fetchAllOrdersForStore.pending]: (state, action) => {
      state.pending++;
    },
    [fetchAllOrdersForStore.fulfilled]: (state, { payload: data }) => {
      state.pending--;
      if (data) {
        driveOrderAdapter.upsertMany(state, data);
      }
    },
    [fetchAllOrdersForStore.rejected]: (state, action) => {
      state.pending--;
    },
    [fetchOrderById.pending]: (state, action) => {
      state.pending++;
    },
    [fetchOrderById.fulfilled]: (state, { payload: data }) => {
      state.pending--;
      if (data) {
        driveOrderAdapter.upsertOne(state, data[0]);
      }
    },
    [fetchOrderById.rejected]: (state, action) => {
      state.pending--;
    },
    [fetchOrdersBySearchFilter.pending]: (state, action) => {
      state.pending++;
    },
    [fetchOrdersBySearchFilter.fulfilled]: (state, { payload: data }) => {
      state.pending--;
      if (data) {
        driveOrderAdapter.upsertMany(state, data);
      }
    },
    [fetchOrdersBySearchFilter.rejected]: (state, action) => {
      state.pending--;
    },
    [fetchOrderByDisplayId.pending]: (state, action) => {
      state.pending++;
    },
    [fetchOrderByDisplayId.fulfilled]: (state, { payload: data }) => {
      state.pending--;
      if (data && data[0]) {
        driveOrderAdapter.upsertOne(state, data[0]);
      }
    },
    [fetchOrderByDisplayId.rejected]: (state, action) => {
      state.pending--;
    },
  },
});

const { reducer } = slice;
export default reducer;

export const {
  selectTotal: selectTotalDriveOrders,
  selectAll: selectAllDriveOrders,
  selectById: selectDriverOrderById,
} = driveOrderAdapter.getSelectors((state) => state.entities.driveOrder);
