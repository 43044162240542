import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";

import { get } from "../api/MsApi";

const creativesAdapter = createEntityAdapter({});

export const fetchMatchingCreatives = createAsyncThunk(
  "entities/fetchMatchingCreatives",
  async (filters, { dispatch }) => {
    if (!filters) {
      return (await get({ dispatch, entity: "creative" })).data;
    } else {
      return (await get({ dispatch, entity: "creative", params: filters }))
        .data;
    }
  }
);

export const fetchAllCreatives = createAsyncThunk(
  "entities/fetchAllCreatives",
  async ({ force } = {}, { getState, dispatch }) => {
    const state = getState();
    const totalCreatives = selectTotalCreatives(state);
    if (!force && (totalCreatives || state.entities.creatives.pending > 1)) {
      return;
    }
    dispatch(fetchMatchingCreatives());
  }
);

export const slice = createSlice({
  name: "creatives",
  initialState: creativesAdapter.getInitialState({ pending: 0 }),
  reducers: {
    clearData: (state) => creativesAdapter.setAll(state, []),
  },
  extraReducers: {
    [fetchMatchingCreatives.pending]: (state, action) => {
      state.pending++;
    },
    [fetchMatchingCreatives.fulfilled]: (state, { payload: data }) => {
      state.pending--;
      if (data) {
        creativesAdapter.upsertMany(state, data);
      }
    },
    [fetchMatchingCreatives.rejected]: (state, action) => {
      state.pending--;
    },
  },
});

const {
  reducer,
  actions: { clearData },
} = slice;
export { clearData };
export default reducer;

export const {
  selectTotal: selectTotalCreatives,
  selectAll: selectAllCreatives,
} = creativesAdapter.getSelectors((state) => state.entities.creatives);
