import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";

import { get } from "../api/MsApi";

const campaignsAdapter = createEntityAdapter({});

export const fetchMatchingCampaigns = createAsyncThunk(
  "entities/fetchMatchingCampaigns",
  async (filters, { dispatch }) => {
    if (!filters) {
      return (await get({ dispatch, entity: "campaign" })).data;
    } else {
      return (await get({ dispatch, entity: "campaign", params: filters }))
        .data;
    }
  }
);

export const fetchCampaignById = createAsyncThunk(
  "entities/fetchCampaignById",
  async (id, { dispatch }) => {
    return await dispatch(fetchMatchingCampaigns({ id }));
  }
);

export const slice = createSlice({
  name: "campaigns",
  initialState: campaignsAdapter.getInitialState({ pending: 0 }),
  reducers: {
    clearData: (state) => campaignsAdapter.setAll(state, []),
  },
  extraReducers: {
    [fetchMatchingCampaigns.pending]: (state, action) => {
      state.pending++;
    },
    [fetchMatchingCampaigns.fulfilled]: (state, { payload: data }) => {
      state.pending--;
      if (data) {
        campaignsAdapter.upsertMany(state, data);
      }
    },
    [fetchMatchingCampaigns.rejected]: (state, action) => {
      state.pending--;
    },
  },
});

const {
  reducer,
  actions: { clearData },
} = slice;
export { clearData };
export default reducer;

export const {
  selectTotal: selectTotalCampaigns,
  selectAll: selectAllCampaigns,
  selectById: selectCampaignById,
} = campaignsAdapter.getSelectors((state) => {
  return state.entities.campaigns;
});
