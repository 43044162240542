import { sentryReduxEnhancer } from "@mobsuccess-devops/react-sentry";
import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";

import msApiReducer from "../features/api/msApiSlice";
import campaignOverviewReducer from "../features/campaignOverview/campaignOverviewSlice";

/* entities */
import entitiesReducer from "../features/entities/entitiesSlice";
import msTestReducer from "../features/mstest/msTestSlice";

const store = configureStore({
  reducer: {
    msTest: msTestReducer,
    msApi: msApiReducer,

    campaignOverview: campaignOverviewReducer,

    /* entities */
    entities: entitiesReducer,
  },
  enhancers: [sentryReduxEnhancer],
  middleware: [
    ...getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }),
  ],
});
export default window.reduxStore = store;
