import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";

import { get } from "../api/MsApi";

const clientAdapter = createEntityAdapter({
  selectId: (x) => x.client,
});

export const fetchAllClients = createAsyncThunk(
  "entities/fetchAllClients",
  async ({ force } = {}, { getState, dispatch }) => {
    const state = getState();
    const totalClients = selectTotalClients(state);
    if (!force && (totalClients || state.entities.clients.pending > 1)) {
      return;
    }
    return (await get({ dispatch, entity: "client" })).data;
  }
);

export const slice = createSlice({
  name: "clients",
  initialState: clientAdapter.getInitialState({ pending: 0 }),
  reducers: {
    loaded: (state, data) => data.payload,
  },
  extraReducers: {
    [fetchAllClients.pending]: (state, action) => {
      state.pending++;
    },
    [fetchAllClients.fulfilled]: (state, { payload: data }) => {
      state.pending--;
      if (data) {
        clientAdapter.setAll(state, data);
      }
    },
    [fetchAllClients.rejected]: (state, action) => {
      state.pending--;
    },
  },
});

const { reducer } = slice;
export default reducer;

export const {
  selectTotal: selectTotalClients,
  selectAll: selectAllClients,
} = clientAdapter.getSelectors((state) => state.entities.clients);
