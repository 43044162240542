import React, { memo, useEffect, useState } from "react";

import PropTypes from "prop-types";

function EntryPointComponentBase({ entryPoint }) {
  const [exportComponent, setExportComponent] = useState(null);
  useEffect(() => {
    (async () => {
      const exportComponent = await import(
        `../../entry-points/${entryPoint.split("/")[0]}`
      );
      setExportComponent(exportComponent);
    })();
  }, [entryPoint]);
  return exportComponent ? <exportComponent.default /> : null;
}

EntryPointComponentBase.propTypes = {
  entryPoint: PropTypes.string.isRequired,
};

export default memo(EntryPointComponentBase);
