import React, {
  useMemo,
  useEffect,
  useState,
  useCallback,
  useContext,
} from "react";

import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import CloseIcon from "@material-ui/icons/Close";

import clsx from "clsx";

const LEFT = 0;
const LEFT_BAR_WRAPPER_WIDTH = 50;

const SlideOverContext = React.createContext();

const useStyles = makeStyles((theme) => ({
  root: {
    position: "fixed",
    top: 0,
    bottom: 0,
    left: LEFT,
    display: "flex",
    width: `calc(100% - ${LEFT}px)`,
    background: theme.palette.primary.light,
    transition: theme.transitions.create("left"),
  },

  closed: {
    left: "150%",
  },

  folded: {
    left: `calc(100% - ${LEFT_BAR_WRAPPER_WIDTH}px)`,
  },

  leftBarWrapper: {
    top: 0,
    bottom: 0,
    left: 0,
    display: "flex",
    width: LEFT_BAR_WRAPPER_WIDTH,
    flexDirection: "column",
    background: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    cursor: "pointer",
  },

  button: {
    display: "flex",
    width: LEFT_BAR_WRAPPER_WIDTH,
    height: LEFT_BAR_WRAPPER_WIDTH,
    justifyContent: "center",
  },

  icon: {},

  titleWrapper: {
    position: "relative",
    display: "inline-block",
    overflow: "hidden",
    width: LEFT_BAR_WRAPPER_WIDTH,
    flexGrow: 2,
    margin: "0px auto 20px auto",
    fontWeight: "bold",
    userSelect: "none",
  },

  title: {
    transform: "rotate(90deg)",
    transformOrigin: `${LEFT_BAR_WRAPPER_WIDTH / 5}px ${
      LEFT_BAR_WRAPPER_WIDTH / 2
    }px`,
    whiteSpace: "nowrap",
  },

  content: {
    position: "absolute",
    width: `calc(100% - ${LEFT_BAR_WRAPPER_WIDTH}px)`,
    height: "100%",
    marginLeft: LEFT_BAR_WRAPPER_WIDTH,
  },
}));

function DefaultTitleComponent({ children, ...props }) {
  return <div {...props}>{children}</div>;
}

const SlideOverFoldedContext = React.createContext();
function SlideOverFoldedProvider({ children }) {
  const [folded] = useState({});
  const [setFoldedImpls] = useState({});
  const [context, setContext] = useState(() => {
    const context = {
      get: (id) => {
        return folded[id];
      },
      set: ({ id, isFolded }) => {
        folded[id] = isFolded;
        setContext({ ...context });
      },
      getFolded: (id) => folded[id],
      setFolded: (id) => {
        const setFoldedImpl = setFoldedImpls[id];
        if (setFoldedImpl) {
          return setFoldedImpl;
        }
        return (setFoldedImpls[id] = (isFolded) =>
          context.set({ id, isFolded }));
      },
    };
    return context;
  });
  return (
    <SlideOverFoldedContext.Provider value={context}>
      {children}
    </SlideOverFoldedContext.Provider>
  );
}

function useGetFolded() {
  return useContext(SlideOverFoldedContext).getFolded;
}

function useSetFolded() {
  return useContext(SlideOverFoldedContext).setFolded;
}

function SlideOverProvider({ children }) {
  const [, setN] = useState();
  const setFolded = useSetFolded();
  const value = useMemo(() => {
    let zIndex = 90000;
    const elems = {};
    return {
      elems,
      pushElem: ({ id, elem, open }) => {
        setN(Math.random());
        elems[id] = { zIndex: zIndex++, elem };
      },
      getById: (id) => elems[id],
      onClose: (id) => {
        delete elems[id];
        setN(Math.random());
      },
      raise: (id) => {
        elems[id].zIndex = zIndex++;
        setFolded(id)[1](false);
        setN(Math.random());
      },
    };
  }, [setFolded]);

  return (
    <SlideOverContext.Provider value={value}>
      {children}
      {Object.entries(value.elems).map(([id, { zIndex, elem }]) => (
        <div style={{ zIndex, position: "fixed" }} key={id}>
          {elem}
        </div>
      ))}
    </SlideOverContext.Provider>
  );
}

function SlideOverElem({
  className,
  title,
  children,
  TitleComponent,
  open,
  onClose,
  id,
}) {
  const folded = useGetFolded()(id);
  const setFolded = useSetFolded()(id);

  const classes = useStyles();

  const handleFold = useCallback(() => setFolded(true), [setFolded]);
  const handleUnfold = useCallback(() => setFolded(false), [setFolded]);

  const Title = TitleComponent || DefaultTitleComponent;

  return (
    <Paper
      elevation={24}
      className={clsx(
        className,
        classes.root,
        !open && classes.closed,
        open && folded && classes.folded
      )}
    >
      <div className={clsx("SlideOver-bar", classes.leftBarWrapper)}>
        <div
          className={classes.titleWrapper}
          onClick={folded ? handleUnfold : handleFold}
        >
          <Title className={classes.title}>{title}</Title>
        </div>
        {folded ? (
          <div className={classes.button} onClick={handleUnfold}>
            <ChevronLeftIcon className={classes.icon} />
          </div>
        ) : (
          <div className={classes.button} onClick={handleFold}>
            <ChevronRightIcon className={classes.icon} />
          </div>
        )}
        <div className={classes.button} onClick={onClose}>
          <CloseIcon className={classes.icon} />
        </div>
      </div>
      <div className={classes.content}>{open && children}</div>
    </Paper>
  );
}

function SlideOverElemDelay({ openDelay, ...props }) {
  const [open, setOpen] = useState(0);
  useEffect(() => {
    setTimeout(() => {
      setOpen(openDelay);
    }, 100);
  }, [openDelay]);
  return <SlideOverElem {...props} open={open} />;
}

export default function SlideOver(props) {
  const { open, id, onClose } = props;
  const slideOverContext = useContext(SlideOverContext);

  useEffect(() => {
    if (open) {
      const elem = slideOverContext.getById(id);
      if (elem) {
        slideOverContext.raise(id);
      } else {
        const handleOnClose = () => {
          slideOverContext.onClose(id);
          if (onClose) {
            onClose();
          }
        };
        const elem = (
          <SlideOverElemDelay
            {...props}
            onClose={handleOnClose}
            openDelay={open}
          />
        );
        slideOverContext.pushElem({ id, elem, open });
      }
    }
  }, [id, open, slideOverContext, onClose, props]);
  return null;
}

export function SlideOverComboProvider({ children }) {
  return (
    <SlideOverFoldedProvider>
      <SlideOverProvider>{children}</SlideOverProvider>
    </SlideOverFoldedProvider>
  );
}
