import { useEffect } from "react";

export function useScript({ src, javascript }) {
  useEffect(() => {
    const script = document.createElement("script");

    if (src) {
      script.src = src;
    } else if (javascript) {
      script.innerText = javascript;
    }

    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [src, javascript]);
}
