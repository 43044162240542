// extrapolate the number of visits based on the budget over
// which visits has been calculated on all the campaign
export const enableVisitMultiplier = false;

export const language = "fr";

export const defaultRowValue = {
  clickRate: 0,
  clicks: 0,
  cpc: 0,
  cpm: 0,
  cpv: 0,
  expUnique: 0,
  impressions: 0,
  spend: 0,
  pingsMatch: 0,
  deliveredImpressions: 0,
  frequency: 0,
  plannedImpressions: 0,
  visits: 0,
  tdc: 0,
};

export const mapLegendColors = {
  0: "#3BBCF3",
  1: "#41DBB6",
  2: "#ACD439",
  3: "#EBCD32",
  4: "#ECA24D",
};
