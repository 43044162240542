import React, { useState } from "react";

import { makeStyles } from "@material-ui/core/styles";

import { createPortal } from "react-dom";

import MsApiLoadingIndicator from "./MsApiLoadingIndicator";

const useStyles = makeStyles({
  standalone: {
    position: "fixed",
    zIndex: 9999999,
  },
});

export default function MsApiLoadingIndicatorPortal() {
  const classes = useStyles();
  const [container] = useState(
    document.getElementById("react_loading_indicator")
  );
  if (container) {
    return createPortal(<MsApiLoadingIndicator />, container);
  } else {
    return <MsApiLoadingIndicator className={classes.standalone} />;
  }
}
