import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";

import { get } from "../api/MsApi";

const opeAdapter = createEntityAdapter({
  selectId: (x) => x.id,
});

export const fetchMatchingOpes = createAsyncThunk(
  "entities/fetchMatchingOpes",
  async (filters, { dispatch }) => {
    if (!filters) {
      return (await get({ dispatch, entity: "ope" })).data;
    } else {
      return (await get({ dispatch, entity: "ope", params: filters })).data;
    }
  }
);

export const fetchAllOpes = createAsyncThunk(
  "entities/fetchAllOpes",
  async ({ force } = {}, { getState, dispatch }) => {
    const state = getState();
    const totalOpes = selectTotalOpes(state);
    if (!force && (totalOpes || state.entities.accounts.pending > 1)) {
      return;
    }
    dispatch(fetchMatchingOpes());
  }
);

export const slice = createSlice({
  name: "opes",
  initialState: opeAdapter.getInitialState({ pending: 0 }),
  reducers: {
    clearData: (state) => opeAdapter.setAll(state, []),
  },
  extraReducers: {
    [fetchMatchingOpes.pending]: (state, action) => {
      state.pending++;
    },
    [fetchMatchingOpes.fulfilled]: (state, { payload: data }) => {
      state.pending--;
      if (data) {
        opeAdapter.upsertMany(state, data);
      }
    },
    [fetchMatchingOpes.rejected]: (state, action) => {
      state.pending--;
    },
  },
});

const {
  reducer,
  actions: { clearData },
} = slice;
export { clearData };
export default reducer;

export const {
  selectTotal: selectTotalOpes,
  selectAll: selectAllOpes,
} = opeAdapter.getSelectors((state) => state.entities.opes);
