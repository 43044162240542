import React from "react";

import { ThemeProvider } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";

import { theme as defaultTheme } from "../msTheme";

const useStyles = makeStyles((theme) => ({
  root: {
    "&, button,  select,  input": {
      /* stylelint-disable font-family-no-missing-generic-family-keyword  */
      fontFamily: theme.typography.fontFamily,
    },
  },
}));

function Root({ children }) {
  const classes = useStyles();
  return <div className={classes.root}>{children}</div>;
}

export default function MsThemeProvider({ theme, children }) {
  if (!theme) {
    theme = defaultTheme;
  }
  return (
    <ThemeProvider theme={theme}>
      <Root>{children}</Root>
    </ThemeProvider>
  );
}
