import React from "react";

import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";

import clsx from "clsx";
import { useSelector } from "react-redux";

import { selectRunningRequests } from "../features/api/msApiSlice";

const useStyles = makeStyles({
  apiIndicator: {
    top: 0,
    right: 0,
    padding: 6.4,
  },
});

function MsApiLoadingIndicatorBase({ className = null, size = 16, visible }) {
  const classes = useStyles();

  return (
    <CircularProgress
      size={size}
      //color={theme.palette.primary.dark}
      classes={{ root: clsx(className, classes.apiIndicator) }}
    />
  );
}

export default function MsApiLoadingIndicator(props) {
  const runningRequests = useSelector(selectRunningRequests);
  return runningRequests ? <MsApiLoadingIndicatorBase {...props} /> : null;
}
