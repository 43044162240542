import "@mobsuccess-devops/mobsuccess-query";
import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";

export { useQuery, useQueryV2 } from "@mobsuccess-devops/react-data-context";

const queryAdapter = createEntityAdapter({});

export const slice = createSlice({
  name: "query",
  initialState: queryAdapter.getInitialState({}),
  reducers: {},
  extraReducers: {},
});

const {
  reducer,
  actions: { clearData },
} = slice;
export { clearData };
export default reducer;

export const { selectById: selectQueryById } = queryAdapter.getSelectors(
  (state) => state.entities.query
);
