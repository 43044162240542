import React, { useContext, useState, useCallback, useMemo } from "react";

import { i18n } from "@lingui/core";
import { t } from "@lingui/macro";
import { Trans } from "@lingui/macro";
import { I18nProvider } from "@lingui/react";

import { format as formatDateFns } from "date-fns";
import { fr as dateFnsFr, enUS as dateFnsEn } from "date-fns/locale";
import { fr as pluralsFr, en as pluralsEn } from "make-plural/plurals";

import { language } from "../features/dashboard-v2/constants";
import en from "../locales/en/messages";
import fr from "../locales/fr/messages";

window.i18n = i18n;
i18n.loadLocaleData("en", { plurals: pluralsEn });
i18n.loadLocaleData("fr", { plurals: pluralsFr });
i18n.load("en", en.messages);
i18n.load("fr", fr.messages);

export function activate(locale) {
  if (activate.current !== locale) {
    activate.current = locale;
    i18n.activate(locale);
  }
}

const LanguageContext = React.createContext({
  language,
  onChangeLanguage: () => null,
});

export function getAllowedLanguages() {
  return ["fr", "en"];
}

// fix Lingui.JS that sometimes mangles the spaces
const oldNumberFormat = Intl.NumberFormat;
Intl.NumberFormat = function (locale) {
  const result = oldNumberFormat.apply(this, arguments);
  return {
    format: function () {
      return result.format.apply(this, arguments).replace(/\s/g, " ");
    },
    formatToParts: function () {
      return result.formatToParts.apply(this, arguments);
    },
    resolvedOptions: function () {
      return result.resolvedOptions.apply(this, arguments);
    },
  };
};

export default function Localized({ language, children }) {
  const [currentLanguage, setCurrentLanguage] = useState(language);

  const handleChangeLanguage = useCallback((newLanguage) => {
    setCurrentLanguage(newLanguage);
  }, []);

  const value = useMemo(() => {
    return {
      language: currentLanguage,
      onChangeLanguage: handleChangeLanguage,
    };
  }, [currentLanguage, handleChangeLanguage]);

  activate(currentLanguage);
  return (
    <LanguageContext.Provider value={value}>
      <I18nProvider i18n={i18n} language={currentLanguage}>
        {children}
      </I18nProvider>
    </LanguageContext.Provider>
  );
}

export function useLanguage() {
  return useContext(LanguageContext).language;
}

export function useOnChangeLanguage() {
  return useContext(LanguageContext).onChangeLanguage;
}

export function useI18n() {
  const language = useLanguage();
  activate(language);
  return i18n;
}

export function formatPercentageForLanguage({ value, language }) {
  i18n.activate(language);
  if (isNaN(value)) {
    return t`n/a`;
  }
  switch (language) {
    case "fr":
      return t`${i18n.number(Math.round(value * 1000) / 10)} %`;
    default:
      return t`${i18n.number(Math.round(value * 1000) / 10)}%`;
  }
}

export function PercentFormat({ value }) {
  const language = useLanguage();
  return formatPercentageForLanguage({ value, language });
}

export function useDateFnsLocale() {
  const language = useLanguage();
  switch (language) {
    case "fr":
      return dateFnsFr;
    case "en":
      return dateFnsEn;
    default:
      throw new Error(`unknown language ${language}`);
  }
}

export function useShortDateFormat() {
  const language = useLanguage();
  switch (language) {
    case "fr":
      return "d LLL";
    default:
      return "LLL d";
  }
}

export function useDurationFormatter() {
  const language = useLanguage();

  return (date1, date2) => {
    function dateToString(d) {
      const year = (d.getYear() + 1900).toString();
      const month = d.getMonth() + 1;
      const day = d.getDate();
      return `${year}-${month < 10 ? "0" : ""}${month}-${
        day < 10 ? "0" : ""
      }${day}`;
    }

    const today = new Date(dateToString(new Date())).getTime();
    const distance1 = (today - new Date(date1)) / 86400e3,
      distance2 = (today - new Date(date2)) / 86400e3;
    switch (language) {
      case "en":
      case "fr":
        if (distance1 === 0 && distance2 === 0) {
          return <Trans>Today</Trans>;
        } else if (distance1 === 1 && distance2 === 1) {
          return <Trans>Yesterday</Trans>;
        } else {
          return `${formatDateFns(new Date(date1), "PP")} - ${formatDateFns(
            new Date(date2),
            "PP"
          )}`;
        }
      default:
        throw new Error(`Unknown language ${language}`);
    }
  };
}
