import { createSlice } from "@reduxjs/toolkit";

export const { actions, reducer } = createSlice({
  name: "campaignOverview",
  initialState: {
    client: "",

    showAppMarketing: false,
    showRTB: false,

    showEnded: false,
    showRunning: true,
    showCapped: true,
    showPaused: true,
    showFuture: true,

    showDisplay: true,
    showVideo: true,
    showFacebook: true,

    date1: new Date().toISOString().substr(0, 10),
    date2: new Date().toISOString().substr(0, 10),
  },
  reducers: {
    setClient: (state, action) => {
      state.client = action.payload;
    },
    setShowAppMarketing: (state, action) => {
      state.showAppMarketing = action.payload;
    },
    setShowRTB: (state, action) => {
      state.showRTB = action.payload;
    },
    setShowEnded: (state, action) => {
      state.showEnded = action.payload;
    },
    setShowRunning: (state, action) => {
      state.showRunning = action.payload;
    },
    setShowCapped: (state, action) => {
      state.showCapped = action.payload;
    },
    setShowPaused: (state, action) => {
      state.showPaused = action.payload;
    },
    setShowFuture: (state, action) => {
      state.showFuture = action.payload;
    },
    setDate1: (state, action) => {
      state.date1 = action.payload;
    },
    setDate2: (state, action) => {
      state.date2 = action.payload;
    },
    setShowDisplay: (state, action) => {
      state.showDisplay = action.payload;
    },
    setShowVideo: (state, action) => {
      state.showVideo = action.payload;
    },
    setShowFacebook: (state, action) => {
      state.showFacebook = action.payload;
    },
  },
});

export const {
  setClient,
  setShowAppMarketing,
  setShowRTB,
  setShowEnded,
  setShowRunning,
  setShowCapped,
  setShowPaused,
  setShowFuture,
  setDate1,
  setDate2,
  setShowDisplay,
  setShowVideo,
  setShowFacebook,
} = actions;

export const selectClient = (state) => state.campaignOverview.client;
export const selectShowAppMarketing = (state) =>
  state.campaignOverview.showAppMarketing;
export const selectShowRTB = (state) => state.campaignOverview.showRTB;

export const selectShowEnded = (state) => state.campaignOverview.showEnded;
export const selectShowRunning = (state) => state.campaignOverview.showRunning;
export const selectShowCapped = (state) => state.campaignOverview.showCapped;
export const selectShowPaused = (state) => state.campaignOverview.showPaused;
export const selectShowFuture = (state) => state.campaignOverview.showFuture;

export const selectDate1 = (state) => state.campaignOverview.date1;
export const selectDate2 = (state) => state.campaignOverview.date2;

export const selectShowDisplay = (state) => state.campaignOverview.showDisplay;
export const selectShowVideo = (state) => state.campaignOverview.showVideo;
export const selectShowFacebook = (state) =>
  state.campaignOverview.showFacebook;

export default reducer;
